<template>
  <!-- Modal Active Business-->
  <app-modal
    v-if="hasActiveBusiness && isTestMode == false"
    :show="showModalActive"
    @close="showModalActive = false"
    :textButtonCancel="$t('payment.info.active_later')"
  >
    <template v-slot:title>{{
      $t("payment.info.active_your_account_title")
    }}</template>
    <template v-slot:body>
      <p class="text-sm text-gray-500">
        {{ $t("payment.info.active_your_account_description") }}
      </p>
    </template>
    <template v-slot:action>
      <app-button
        @onClick="$router.push({ name: 'business' })"
        @click="showModalActive = false"
        :showf70Icon="false"
        :primary="false"
        class="
          sm:ml-3 sm:w-auto
          bg-primary
          hover:bg-primary-100
          text-white
          outline-none
        "
      >
        {{ $t("payment.info.active_account") }}
      </app-button>
    </template>
  </app-modal>

  <transition>
    <div class="flex flex-col md:flex-row w-full">
      <div
        :class="[
          'flex flex-col w-full py-2 md:py-10 px-4 md:px-10',
          showPreview ? 'md:w-2/5' : 'md:w-4/5',
        ]"
      >
        <p class="mb-5 font-semibold text-xl">
          {{
            edit_mode
              ? $t("payment.open-payment-link.form.edit_form_title")
              : $t("payment.open-payment-link.form.form_title")
          }}
        </p>
        <form
          class="flex flex-col space-y-4 items-start"
          @submit.prevent="createOpenPaymentLink"
        >

          <app-form-select-master-business-list-business
           v-if="!edit_mode"
            class="w-full"
            v-model="open_payment_link.business"
            :loading="loading"
            :show="showSelectBusiness"
            @close="showSelectBusiness = false"
            :errorMessage="errors.business_id"
          />

          <!-- <app-form-select
            class="w-full"
            :datas="paymentType"
            :labelText="$t('payment.open-payment-link.form.payment_type')"
            :placeholder="$t('payment.open-payment-link.form.payment_type')"
            :errorMessage="errors.paymentType"
            v-model="open_payment_link.payment_type"
            :allowFilter="false"
            @valueSelected="handlePaymentTypeSelected"
          /> -->
          
          <app-form-checkbox
            v-model="open_payment_link.is_recurring"
            v-show="is_recurring"
            @click="handleIsRecurring(open_payment_link.is_recurring)"
          >
            {{ $t("payment.open-payment-link.form.is_recurring") }}
          </app-form-checkbox>

          <app-form-select
            class="w-full"
            :datas="billingPeriod"
            :labelText="$t('payment.open-payment-link.form.billing_period')"
            :placeholder="$t('payment.open-payment-link.form.billing_period')"
            v-model="open_payment_link.billing_period"
            :allowFilter="false"
            v-if="open_payment_link.is_recurring"
          />

          <app-form-input
            class="w-full"
            type="text"
            :labelText="$t('payment.open-payment-link.form.title')"
            :placeholder="$t('payment.create.title_placeholder')"
            v-model="open_payment_link.title"
            :errorMessage="errors.title"
          />

          <app-form-input-with-select
            class="w-full"
            type="number"
            step=".01"
            :labelText="$t('payment.open-payment-link.form.minimum_amount')"
            :placeholder="$t('payment.create.amount_placeholder')"
            v-model="open_payment_link.formatted_minimum_amount"
            v-model:option="open_payment_link.currency"
            :errorMessage="errors.minimum_amount"
            @input="validateAmount"
            maxLength="7"
            :options="[
              {
                id: 1,
                desc: 'MYR',
              },
            ]"
          />

          <app-form-textarea
            class="w-full"
            :labelText="$t('payment.open-payment-link.form.desc')"
            :placeholder="$t('payment.create.desc_placeholder')"
            v-model="open_payment_link.description"
            :errorMessage="errors.description"
          />
          
          <app-form-textarea
            class="w-full"
            type="text"
            :labelText="$t('payment.open-payment-link.form.declaration')"
            :placeholder="$t('payment.open-payment-link.form.declaration_placeholder')"
            v-model="open_payment_link.declaration"
            :errorMessage="errors.declaration"
          />

          <app-button-upload-image
            :labelText="$t('payment.open-payment-link.form.banner')"
            :category="MEDIA_CATEGORY.BANNER"
            :aspectRatio="16 / 9"
            @onTempUpload="onBannerUpload"
            :preview="openPaymentLinkBanner"
          />

          <app-form-input
            class="w-full"
            type="text"
            :labelText="$t('payment.open-payment-link.form.ref1')"
            :placeholder="$t('payment.open-payment-link.form.ref1_placeholder')"
            v-model="open_payment_link.reference"
            :errorMessage="errors.reference"
          />

          <app-form-input
            class="w-full"
            type="text"
            :labelText="$t('payment.open-payment-link.form.ref2')"
            :placeholder="$t('payment.open-payment-link.form.ref2_placeholder')"
            v-model="open_payment_link.reference_2"
            :errorMessage="errors.reference_2"
          />

          <app-form-input
            class="w-full"
            type="url"
            :labelText="$t('payment.open-payment-link.form.redirect_url')"
            :placeholder="$t('payment.open-payment-link.form.redirect_url_placeholder')"
            v-model="open_payment_link.redirect_url"
            :errorMessage="errors.redirect_url"
          />

          <!-- Show Errors -->
          <div
            class="flex flex-col mt-4"
            v-if="errors.open_payment_link && !preview_mode"
          >
            <p class="text-error">
              {{ errors.open_payment_link[0] }}
            </p>
          </div>

          <div />

          <app-button
            type="submit"
            :loading="loading"
            class="w-full"
            :showProceedIcon="true"
            :disabled="hasActiveBusiness &&  isTestMode == false"
          >
            {{
              edit_mode
                ? $t("payment.open-payment-link.form.button_save")
                : $t("payment.open-payment-link.form.button_create")
            }}
          </app-button>

          <p v-if="hasActiveBusiness &&  isTestMode == false" class="text-gray-500 text-sm">
            {{ $t("payment.info.active_your_account") }}
          </p>
        </form>
      </div>
      <div
        :class="[
          'md:overflow-y-auto md:border-l py-4 md:py-8 px-4 md:px-10 flex flex-col',
          showPreview ? 'md:w-3/5' : 'md:w-1/5',
        ]"
      >
        <div
          :class="[
            'flex flex-col md:flex-row items-center md:space-x-4',
            showPreview ? '' : 'justify-center',
          ]"
        >
          <p
            class="
              w-full
              text-center
              md:text-left md:w-auto
              text-xl
              font-semibold
              mb-2
              md:mb-0
            "
            v-if="showPreview"
          >
            {{ $t("payment.create.preview_title") }}
          </p>
          <app-button
            :primary="false"
            :showf70Icon="false"
            width="w-full md:w-auto"
            :class="[togglePreview ? 'mb-0' : 'mb-4']"
            @click="togglePreview"
          >
            {{
              showPreview
                ? $t("payment.create.preview_hide")
                : $t("payment.create.preview_show")
            }}
          </app-button>
        </div>

        <div
          class="flex flex-row mt-4 justify-center md:justify-start"
          v-if="showPreview"
        >
          <app-button-menu
            class="
              flex-none
              w-auto
              space-x-3
              flex
              items-center
              font-medium
              border-b-2
              hover:border-primary
              h-auto
            "
            :rounded="false"
            :active="tabSelectedNo == 1"
            @onMenuClick="onTabClick(1)"
          >
            {{ $t("payment.create.preview_tab_payment_page") }}
          </app-button-menu>
          <div class="border-b-2 w-0 md:w-auto flex-none md:flex-grow">
            &nbsp;
          </div>
        </div>

        <div
          class="mt-4 md:mt-8 flex flex-col"
          v-if="showPreview && tabSelectedNo == 1"
        >
          <div
            class="
              bg-gray-200
              rounded-t-md
              flex flex-row
              px-2
              py-2
              items-center
              space-x-1
              md:space-x-3
              pr-3
            "
          >
            <div class="flex flex-row space-x-1">
              <div class="bg-gray-300 rounded-lg w-2 md:w-3 h-2 md:h-3"></div>
              <div class="bg-gray-300 rounded-lg w-2 md:w-3 h-2 md:h-3"></div>
              <div class="bg-gray-300 rounded-lg w-2 md:w-3 h-2 md:h-3"></div>
            </div>
            <div
              class="
                flex flex-row
                bg-gray-100
                md:w-full
                rounded-md
                px-2
                py-1
                items-center
              "
            >
              <LockClosedIcon class="w-3 h-3 mr-1" />
              <p class="text-xs">{{ payment_link_url }}</p>
            </div>
          </div>

          <template v-if="this.showIfExist">
            <LinkPage
              class="rounded-b-md"
              :previewMode="true"
              :masterBusinessMode="true"
            />
          </template>

          <template v-else>
            <LinkPage class="rounded-b-md" :previewMode="true" />
          </template>
        </div>
      </div>
    </div>
  </transition>
</template>



<script>
import { LockClosedIcon } from "@heroicons/vue/solid";
import LinkPage from "@/views/pages/main/payment/open-payment-link/payment-page";
import Business_status from "@/utils/const/business_status";
import MEDIA_CATEGORY from "@/utils/const/media_category";
import Role from "@/utils/const/role";

export default {
  components: {
    LockClosedIcon,
    LinkPage,
  },

  props: {
    edit_mode: {
      type: Boolean,
      default: false,
    },
    test_mode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      Role: Role,
      showModalActive: true,
      showSelectBusiness: true,
      showPreview: true,
      tabSelectedNo: 1,
      maxAmount: 30000,
      minAmount: 5,
      MEDIA_CATEGORY: MEDIA_CATEGORY,
      checkRoleMasterBusiness: [
        Role.MASTER_BUSINESS_OWNER,
        Role.MASTER_BUSINESS_ADMIN,
        Role.MASTER_BUSINESS_MANAGER,
        Role.MASTER_BUSINESS_DEVELOP,
        Role.MASTER_BUSINESS_SUPPORT,
      ],
      is_recurring: false,
    };
  },

  beforeCreate() {
    this.$store.commit("openPaymentLinkStore/initOpenPaymentLink");
  },

  mounted() {
    this.getPaymentType();
    this.getBillingPeriod();
  },

  computed: {
    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },

    loading() {
      return this.$store.getters["openPaymentLinkStore/loading"];
    },

    paymentType() {
      return this.$store.getters["openPaymentLinkStore/payment_type"];
    },

    billingPeriod() {
      return this.$store.getters["openPaymentLinkStore/billing_period"];
    },

    open_payment_link() {
      return this.$store.getters["openPaymentLinkStore/open_payment_link"];
    },

    errors() {
      return (
        this.$store.getters["openPaymentLinkStore/errors"] ?? this.emptyErrors
      );
    },

    payment_link_url() {
      return (
        process.env.VUE_APP_BASE_URL +
        "/payment/open-link/pay?id=<" +
        this.$t("general.generated_id") +
        ">"
      );
    },

    openPaymentLinkBanner() {
      return (
        this.open_payment_link?.media?.find(
          (media) => media.collection_name == MEDIA_CATEGORY.BANNER
        )?.original_url ?? null
      );
    },

    business() {
      return this.$store.getters["businessStore/business"];
    },

    hasActiveBusiness() {
      if (this.business.business_status_id == null) {
        return false;
      }

      return this.business.business_status_id != Business_status.APPROVED;
    
     },

    showIfExist() {
      return this.checkRoleMasterBusiness.includes(this.selectedRole?.role_id);
    },
    
  
    isTestMode() {
      return this.$store.getters["businessStore/test_mode"];
    }
  },

  watch: {
    "payment_link.business"(value) {
      this.open_payment_link.business_id = value.id;
    },
  },

  methods: {
    async getPaymentType() {
      await this.$store.dispatch("openPaymentLinkStore/retriveAllPaymentType");
    },

    async getBillingPeriod() {
      await this.$store.dispatch("openPaymentLinkStore/retriveBillingPeriod");
    },

    handlePaymentTypeSelected(value) {
      if(value.name == 'Donation'){
        return this.is_recurring = true;
      }

      this.open_payment_link.billing_period = null;
      this.open_payment_link.is_recurring = false;
      this.is_recurring = false;
    },

    handleIsRecurring(value) {
      let defaultValue = !value;
      
      if(defaultValue == false){
        this.open_payment_link.billing_period = null;
      }
    },

    validateAmount(event) {
      const minimum_amount = parseFloat(event.target.value.trim());

      if (minimum_amount > this.maxAmount) {
        this.errors.minimum_amount = [
          this.$t("payment.create.max_amount", {
            max_amount: this.$formats.formattedCurrency(
              this.open_payment_link?.currency,
              this.maxAmount
            ),
          }),
        ];
        return;
      }

      if (minimum_amount < this.minAmount) {
        this.errors.minimum_amount = [
          this.$t("payment.create.min_amount", {
            min_amount: this.$formats.formattedCurrency(
              this.open_payment_link?.currency,
              this.minAmount
            ),
          }),
        ];
        return;
      }

      this.errors.minimum_amount = [];
    },

    togglePreview() {
      this.showPreview = !this.showPreview;
    },

    onBannerUpload(tempUrl) {
      this.open_payment_link.banner_url = tempUrl?.media_path;
      this.open_payment_link.media = [];
      if (tempUrl.media_url != null) {
        this.open_payment_link.media.push({
          collection_name: MEDIA_CATEGORY.BANNER,
          original_url: tempUrl.media_url,
        });
      }
    },

    onTabClick(tabNo) {
      this.tabSelectedNo = tabNo;
    },

    async createOpenPaymentLink() {
      this.resetState();

      const openPaymentLink = await this.$store.dispatch(
        "openPaymentLinkStore/createOrUpdateOpenPaymentLink"
      );

      if (openPaymentLink == null) return;

      this.$router.push({ path: "/payment/open-link" });
    },

    resetState() {
      this.$store.commit("openPaymentLinkStore/setErrorMessage");
    },
  },
};
</script>